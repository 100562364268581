<template>
  <div>
    <establecimientosList />
  </div>
</template>

<script>
import establecimientosList from './components/EstablecimientosList.vue'

// import establecimientosList from './components/InquilinosList.vue'

export default {
  components: {
    establecimientosList,
  },
  computed: {
    // ...mapGetters({ usuarios }),
  },
  created() {
    // Consultar autenticación del
  },
}
</script>

<style>

</style>
